body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Calibri',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#sign-in-button:hover {
  background-color: lightgray;
}